import { useState, useEffect, useRef } from 'react';

const useElementWidth = <T extends HTMLElement>() => {
  const [width, setWidth] = useState(0);
  const ref = useRef<T | null>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const updateWidth = () => {
      const rect = element.getBoundingClientRect();
      setWidth(rect.width); // Includes padding and borders
    };

    const resizeObserver = new ResizeObserver(updateWidth);
    resizeObserver.observe(element);

    // Initial width update
    updateWidth();

    return () => {
      resizeObserver.disconnect(); // Cleanup on unmount
    };
  }, []);

  return { ref, width };
};

export default useElementWidth;
