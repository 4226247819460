import React from 'react';
import { FormContainer } from './styledComponents';
import { FormRecord } from '~/config/generated/graphql';
import styled from 'styled-components';
import { InstagramIcon, Subtitle, FacebookIcon } from '@kvdbil/components';
import StructuredText from '~/App/components/DatoCMS/StructuredText';
import Link from '~/App/shared/components/Link';
import { useTranslation } from '~/Locale';

const Title = styled(Subtitle)`
  text-align: center;
`;

const DescriptionWrapper = styled.div`
  text-align: center;
  margin: 0.75rem 0 2rem 0;
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 2rem;
  justify-content: center;
  margin-top: 2rem;
`;

const SocialMedia = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  svg {
    font-size: 1.5rem;
  }
`;

type Props = {
  title: FormRecord['formTitle'];
  description:
    | FormRecord['formNotOpenDescription']
    | FormRecord['formAlreadyClosedDescription'];
};

const BeforeStartOrAfterEndInfo = ({ title, description }: Props) => {
  const { t } = useTranslation();

  return (
    <FormContainer>
      <Title as="h3">{title}</Title>
      <DescriptionWrapper>
        <StructuredText data={description} />
        <SocialMediaWrapper>
          <SocialMedia>
            <FacebookIcon aria-label="kvd on facebook" />
            <Link to={t('FOOTER_FACEBOOK_LINK')}>{'Facebook'}</Link>
          </SocialMedia>
          <SocialMedia>
            <InstagramIcon aria-label="kvd on instagram" />
            <Link to={t('FOOTER_INSTAGRAM_LINK')}>{'Instagram'}</Link>
          </SocialMedia>
        </SocialMediaWrapper>
      </DescriptionWrapper>
    </FormContainer>
  );
};

export default BeforeStartOrAfterEndInfo;
