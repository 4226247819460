import { FormData } from '~/App/views/DynamicPage/components/DynamicForm/DynamicForm';
import axios from '~/helpers/orchestration/axiosWithAuthHandling';
import { orchestration } from '~/config/public';
import { Locale } from '~/App/shared/localization/types';

export type PostDynamicFormDataArgs = {
  formInternalName: string;
  formData: FormData;
  locale: Locale;
  gRecaptchaResponse: string;
};

export const postDynamicForm = ({
  formInternalName,
  formData,
  locale,
  gRecaptchaResponse
}: PostDynamicFormDataArgs) =>
  axios.post(
    `${orchestration.url}/common/campaign/form`,
    {
      campaign: { formInternalName, formData, locale },
      gRecaptchaResponse
    },
    {
      responseType: 'json'
    }
  );
