import {
  mq,
  resetButtonStyle,
  ArrowLeftIcon,
  ArrowRightIcon
} from '@kvdbil/components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useScroller } from '../hooks/useScroller';
import VehicleCardListContainer from '~/App/shared/components/VehicleCardList/components/VehicleCardListContainer';
import VehicleCardListItem, {
  WithExtraElementShownOnScreenSize
} from '~/App/shared/components/VehicleCardList/components/VehicleCardListItem';
import { PartialMQSizeValues } from '~/helpers/mq';
import { useIsDevice } from '../hooks/useIsDevice';
import { InView } from 'react-intersection-observer';

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Pagination = styled.div<{ alwaysShow: boolean }>`
  position: absolute;
  z-index: 8;
  top: calc(50% - 1.125rem);
  left: 0;
  height: 0;
  width: 100%;

  ${({ alwaysShow }) =>
    !alwaysShow &&
    `
      display: none;
      ${mq('laptop')} {
        display: block;
      }
    `}
`;

const Button = styled.button`
  ${resetButtonStyle};
  background: ${({ theme }) => theme.colors.secondary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-out;

  color: ${({ theme }) => theme.colors.common.white};
  font-size: 0.75rem;
  position: absolute;
  height: 2.25em;
  width: 2.25em;
  border-radius: 50%;
  svg {
    font-size: 0.9375rem;
  }
`;

const ButtonLeft = styled(Button)`
  left: -0.375rem;
  svg {
    margin-left: -1px;
  }
`;
const ButtonRight = styled(Button)`
  right: -0.375rem;
`;

const SwipeIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 1.6rem 0.5rem;
  gap: 0.5rem;
`;

const Indication = styled.div<{ active: boolean }>`
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primary.main : theme.colors.gray.light5};
  border-radius: 0.5rem;
  height: 0.25rem;
  width: 100%;
  transition: background-color 0.3s;
`;

export interface SlideShowProps {
  children: React.ReactNode[];
  isCrossSaleSlider?: boolean;
  alwaysShowPagination?: boolean;
  itemsPerSlide?: PartialMQSizeValues;
}

export const SlideShow = ({
  children,
  isCrossSaleSlider = false,
  alwaysShowPagination = false,
  itemsPerSlide
}: SlideShowProps) => {
  const [activeItem, setActiveItem] = useState(0);
  const {
    handleMoveClick,
    scroller,
    childRefs,
    onScroll,
    leftStyle,
    rightStyle
  } = useScroller(children);

  const { isMobile } = useIsDevice();
  const items = isMobile ? children.slice(0, 4) : children;

  return (
    <Container>
      <Pagination alwaysShow={alwaysShowPagination}>
        <ButtonLeft
          style={leftStyle}
          onClick={() => handleMoveClick('left')}
          data-testid="left-arrow"
        >
          <ArrowLeftIcon />
        </ButtonLeft>
        <ButtonRight
          style={rightStyle}
          onClick={() => handleMoveClick('right')}
          data-testid="right-arrow"
        >
          <ArrowRightIcon />
        </ButtonRight>
      </Pagination>
      <VehicleCardListContainer
        innerRef={scroller}
        onScroll={onScroll}
        isSlider
      >
        {items.map((item, index) => {
          return (
            <VehicleCardListItem
              key={index}
              innerRef={childRefs[index]}
              {...(isCrossSaleSlider
                ? WithExtraElementShownOnScreenSize
                : itemsPerSlide)}
            >
              <InView
                threshold={[0.5, 1]}
                onChange={inView => inView && setActiveItem(index)}
              >
                {({ ref }) => <div ref={ref}>{item}</div>}
              </InView>
            </VehicleCardListItem>
          );
        })}
      </VehicleCardListContainer>

      <SwipeIndicator>
        {isMobile &&
          items.map((_, index) => (
            <Indication key={index} active={index === activeItem} />
          ))}
      </SwipeIndicator>
    </Container>
  );
};

export default SlideShow;
