import React from 'react';
import { DynamicPageFormRecord } from '~/config/generated/graphql';
import InterestReportForm from '~/App/views/DynamicPage/components/forms/InterestReportForm';
import CorporateCarsContactForm from '~/App/views/DynamicPage/components/forms/CorporateCarsContactForm';
import TransportCostForm from '~/App/views/DynamicPage/components/forms/TransportCostForm';
import Newsletter from '~/App/views/Start/components/Newsletter';
import SellCarForm from './forms/SellCarForm';
import CorporateNewsletterForm from '~/App/views/DynamicPage/components/forms/CorporateNewsletterForm';
import PartnerLeasingForm from './forms/PartnerLeasingForm';

type Props = {
  record: DynamicPageFormRecord;
};

const DynamicPageFormDispatcher = ({ record }: Props) => {
  const { isActive, usageType, title, description, sendButton } = record;

  if (!isActive) {
    return null;
  }

  const commonProps = {
    title: title ?? undefined,
    description: description ?? undefined,
    sendButton: sendButton ?? undefined
  };

  switch (usageType) {
    case 'partnerLeasing':
      return <PartnerLeasingForm {...commonProps} />;
    case 'carLoan':
      return <InterestReportForm {...commonProps} />;
    case 'corporateCars':
      return <CorporateCarsContactForm />;
    case 'transport':
      return <TransportCostForm title={title ?? undefined} />;
    case 'newsletter':
      return <Newsletter {...commonProps} />;
    case 'privateAndCarDealerNewsletter':
      return <Newsletter {...commonProps} useCarDealerSubscription />;
    case 'corporateNewsletter':
      return <CorporateNewsletterForm {...commonProps} />;
    case 'sellCar':
      return <SellCarForm {...commonProps} />;
    default:
      return null;
  }
};

export default DynamicPageFormDispatcher;
