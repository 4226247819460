import { StructuredTextData } from '~/App/shared/interfaces/datoCMS';
import {
  CaptionLink,
  Headline,
  Subtitle,
  TinyTitleBold,
  TinyTitleRegular,
  Title
} from '@kvdbil/components';

export const extractTextStructuredTextObject = (
  obj: StructuredTextData['value'] | null
) => {
  try {
    let values: string[] = [];
    if (obj && typeof obj === 'object') {
      if (Object.prototype.hasOwnProperty.call(obj, 'value')) {
        values.push(obj.value.trim());
      } else {
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            values = values.concat(extractTextStructuredTextObject(obj[key]));
          }
        }
      }
    }
    return values
      .filter(Boolean) // Remove empty strings
      .map(t => t.replace(/<\/?[^>]+(>|$)/g, '').replace(/\n/g, '')) // Remove HTML tags and new lines
      .join(' ');
  } catch (e: unknown) {
    return '';
  }
};

export const getHeadingByNodeLevel = (nodeLevel: 1 | 2 | 3 | 4 | 5 | 6) => {
  switch (nodeLevel) {
    case 1:
      return Headline;
    case 2:
      return Subtitle;
    case 3:
      return TinyTitleBold;
    case 4:
      return TinyTitleRegular;
    case 5:
    case 6:
      return CaptionLink;
  }
};

export const getHeadingByRecordTypename = (
  typename: 'HeadlineRecord' | 'TitleRecord' | 'SubtitleRecord'
) => {
  switch (typename) {
    case 'HeadlineRecord':
      return Headline;
    case 'TitleRecord':
      return Title;
    case 'SubtitleRecord':
      return Subtitle;
  }
};
