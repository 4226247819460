import {
  fieldPropsEmail,
  fieldPropsNationalIdentificationNumber,
  fieldPropsPhoneNumber
} from '~/App/shared/components/Fields/shared/fieldProps';
import { FormField } from './DynamicForm';
import { scroller } from 'react-scroll';

export const getFiledPropsFetcher = (
  textFieldType: FormField['textFieldType']
) => {
  switch (textFieldType) {
    case 'email':
      return fieldPropsEmail;
    case 'nationalIdentificationNumber':
      return fieldPropsNationalIdentificationNumber;
    case 'phoneNumber':
      return fieldPropsPhoneNumber;
    default:
      return null;
  }
};

export const scrollTo = (id: string, headerHeight: number) =>
  scroller.scrollTo(id, {
    duration: 500,
    delay: 100,
    smooth: true,
    isDynamic: true,
    offset: (headerHeight + 32) * -1
  });
