import { mq } from '@kvdbil/components';
import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  margin: 1rem 0 2rem 0;
  gap: 1rem;

  ${mq('tablet')} {
    padding: 1rem 2.5rem;
  }
`;
