import React from 'react';
import { FormData, FormField } from './DynamicForm';
import TextFieldWithControl from '~/App/shared/components/Fields/TextFieldWithControl';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from '~/Locale';
import { getFiledPropsFetcher } from './helpers';
import TextAreaWithControl from '~/App/shared/components/Fields/TextAreaWithControl';
import CheckBoxWithControl from '~/App/shared/components/Fields/CheckBoxWithControl';
import StructuredText from '~/App/components/DatoCMS/StructuredText';
import {
  BodyText,
  Caption,
  CheckBox,
  Flex,
  RadioButton
} from '@kvdbil/components';
import styled from 'styled-components';

const HelperText = styled(Caption)`
  color: ${({ theme }) => theme.colors.text.disabled};
`;

const AnswersWrapper = styled(Flex)`
  padding-left: 1rem;
`;

type Props = {
  field: FormField;
  control: Control<FormData>;
};

const GenericFormFieldDispatcher = ({ field, control }: Props) => {
  const { t } = useTranslation();
  const { fieldType, backendKey, helperText, required } = field;

  switch (fieldType) {
    case 'generic-textField': {
      const { textFieldType, textFieldLabel, textFieldPlaceholder } = field;
      const props = {
        control,
        name: backendKey,
        label: textFieldLabel ?? '',
        helperText: helperText ?? undefined,
        placeholder: textFieldPlaceholder ?? undefined,
        type: ['email', 'number'].includes(textFieldType)
          ? textFieldType
          : undefined
      };

      const getExtraProps = () => {
        const propsFetcher = getFiledPropsFetcher(textFieldType);
        if (propsFetcher) {
          const extraProps = propsFetcher({ t, required });
          return {
            rules: extraProps.rules,
            autoComplete: extraProps.autoComplete
          };
        }

        return {
          rules: {
            required: {
              value: required,
              message: t(`This field can't be blank`)
            }
          }
        };
      };

      return <TextFieldWithControl {...props} {...getExtraProps()} />;
    }
    case 'generic-textArea': {
      const { textAreaRows, textAreaLabel } = field;
      return (
        <TextAreaWithControl
          name={backendKey}
          label={textAreaLabel ?? ''}
          control={control}
          helperText={helperText ?? undefined}
          rows={textAreaRows}
          rules={{
            required: {
              value: required,
              message: t(`This field can't be blank`)
            }
          }}
        />
      );
    }
    case 'generic-checkbox': {
      const { checkboxLabel } = field;
      return (
        <div>
          <CheckBoxWithControl
            name={backendKey}
            label={
              <StructuredText
                data={checkboxLabel}
                options={{ paragraphElem: 'span' }}
              />
            }
            color="info"
            control={control}
            rules={{ required }}
          />
          {helperText && <HelperText as="span">{helperText}</HelperText>}
        </div>
      );
    }
    case 'generic-multipleChoice': {
      const { multipleChoiceLabel, choices, choiceFieldType, required } = field;
      const isMultipleSelection = choiceFieldType === 'checkbox';
      const ChoiceComponent = isMultipleSelection ? CheckBox : RadioButton;

      return (
        <Flex direction="column" gap={0.5}>
          <BodyText>{multipleChoiceLabel}</BodyText>
          <Controller
            name={backendKey}
            control={control}
            rules={{
              validate: value => {
                if (required) {
                  return value.length > 0;
                }
                return true;
              }
            }}
            render={({ field: controllerField, fieldState }) => (
              <AnswersWrapper direction="column" gap={0.5}>
                {choices?.map((choiceField, index) => {
                  const { label } = choiceField;
                  if (!label) return;

                  return (
                    <ChoiceComponent
                      key={`answer_${index}_${label}`}
                      name={`${backendKey}-choice-${index}`}
                      value={label}
                      label={label}
                      checked={controllerField.value.includes(label)}
                      color="info"
                      onChange={e => {
                        const newValue = e.target.value;
                        if (e.target.checked) {
                          controllerField.onChange([
                            ...(isMultipleSelection
                              ? controllerField.value
                              : []),
                            newValue
                          ]);
                        } else {
                          controllerField.onChange(
                            isMultipleSelection
                              ? (controllerField.value as string[]).filter(
                                  val => val !== newValue
                                )
                              : []
                          );
                        }
                      }}
                      hasError={Boolean(fieldState.error)}
                    />
                  );
                })}
              </AnswersWrapper>
            )}
          />
          {helperText && <HelperText as="span">{helperText}</HelperText>}
        </Flex>
      );
    }
    default:
      return null;
  }
};

export default GenericFormFieldDispatcher;
