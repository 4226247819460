import dayjs from 'dayjs';
import React from 'react';
import { FormRecord } from '~/config/generated/graphql';
import BeforeStartOrAfterEndInfo from './DynamicForm/BeforeStartOrAfterEndInfo';
import DynamicForm from './DynamicForm/DynamicForm';

type Props = {
  record: FormRecord;
};

const DynamicFormDisplayController = ({ record }: Props) => {
  const {
    isActive,
    startDate: startDateString,
    endDate: endDateString,
    formTitle,
    formOpenDescription,
    formNotOpenDescription,
    formAlreadyClosedDescription,
    formSuccessSubmissionDescription,
    formFields,
    submitFormDescription,
    formSubmitButton,
    internalName
  } = record;

  const now = dayjs();

  if (
    !isActive ||
    !startDateString ||
    !endDateString ||
    now.isAfter(dayjs(endDateString, 'HH:mm'))
  ) {
    return (
      <BeforeStartOrAfterEndInfo
        title={formTitle}
        description={formAlreadyClosedDescription}
      />
    );
  }

  if (now.isBefore(dayjs(startDateString, 'HH:mm'))) {
    return (
      <BeforeStartOrAfterEndInfo
        title={formTitle}
        description={formNotOpenDescription}
      />
    );
  }

  return (
    <DynamicForm
      fields={formFields}
      submitBtnText={formSubmitButton}
      submitFormDescription={submitFormDescription ?? ''}
      formInternalName={internalName ?? ''}
      title={formTitle}
      description={formOpenDescription}
      successSubmissionDescription={formSuccessSubmissionDescription}
    />
  );
};

export default DynamicFormDisplayController;
