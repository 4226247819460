import { Path } from 'react-hook-form';
import {
  EMAIL_MAX_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  NATIONAL_IDENTIFICATION_NUMBER_MAX_LENGTH,
  ORGANISATION_NAME_MAX_LENGTH
} from '~/App/shared/validation/constants';
import {
  regexEmail,
  regexOrganisationNumber,
  regexSwedishZipCode,
  regexZipCode
} from '~/helpers/regex';
import { TranslateFunction } from '~/Locale';
import { TextFieldProps } from './types';
import { locale } from '~/config/public/environment';
import { CountryOption, getCountries } from '~/helpers/countries';
import { fieldRuleNationalIdentificationNumberPattern } from './fieldRules';

interface FieldPropsArgs<FormState> {
  t: TranslateFunction;
  rules?: TextFieldProps<FormState>['rules'];
  name?: string;
  autoComplete?: string;
  required?: boolean;
  country?: string;
}

export const fieldPropsFullName = <FormState>({
  t,
  rules,
  name = 'fullName',
  autoComplete = 'name',
  required = true
}: FieldPropsArgs<FormState>) => ({
  label: t('Name'),
  rules: {
    required: {
      value: required,
      message: t(`Name can't be blank`)
    },
    ...rules
  },
  name: name as Path<FormState>,
  autoComplete: autoComplete
});

export const fieldPropsPhoneNumber = <FormState>({
  t,
  rules,
  name = 'phoneNumber',
  autoComplete = 'tel',
  required = true
}: FieldPropsArgs<FormState>) => ({
  label: t('Phone number'),
  rules: {
    required: {
      value: required,
      message: t(`Phone number can't be blank`)
    },
    ...rules
  },
  name: name as Path<FormState>,
  autoComplete: autoComplete
});

export const fieldPropsEmail = <FormState>({
  t,
  rules,
  name = 'email',
  autoComplete = 'email',
  required = true
}: FieldPropsArgs<FormState>) => ({
  label: t('Email'),
  rules: {
    required: {
      value: required,
      message: t(`Email can't be blank`)
    },
    maxLength: {
      value: EMAIL_MAX_LENGTH,
      message: `${t(
        'Email is too long (maximum %s characters)',
        EMAIL_MAX_LENGTH
      )}`
    },
    pattern: {
      value: regexEmail,
      message: t('Invalid email address')
    },
    ...rules
  },
  name: name as Path<FormState>,
  autoComplete: autoComplete
});

export const fieldPropsCountry = <FormState>({
  t,
  rules,
  name = 'country',
  autoComplete = 'off',
  required = true
}: FieldPropsArgs<FormState>) => ({
  label: t('Country'),
  rules: {
    required: {
      value: required,
      message: t(`Country can't be blank`)
    },
    validate: (value: { label: string; value: string }) => {
      return value.value !== '' || t(`Country can't be blank`);
    },
    ...rules
  },
  name: name as Path<FormState>,
  autoComplete: autoComplete,
  options: getCountries(t)
});

export const fieldPropsCity = <FormState>({
  t,
  rules,
  name = 'city',
  autoComplete = 'address-level2',
  required = true
}: FieldPropsArgs<FormState>) => ({
  label: t('City'),
  rules: {
    required: {
      value: required,
      message: t(`City can't be blank`)
    },
    ...rules
  },
  name: name as Path<FormState>,
  autoComplete: autoComplete
});

export const fieldPropsPostalCode = <FormState>({
  t,
  rules,
  name = 'zipCode',
  autoComplete = 'postal-code',
  required = true,
  country = 'se'
}: FieldPropsArgs<FormState> & { country?: CountryOption['value'] }) => {
  const shouldUseRegexSwedishZipCode = () => {
    if (country && country === 'se') {
      return true;
    }
    return locale === 'sv';
  };

  return {
    label: t('Postal code'),
    rules: {
      required: {
        value: required,
        message: t(`Postal code can't be blank`)
      },
      pattern: {
        value: shouldUseRegexSwedishZipCode()
          ? regexSwedishZipCode
          : regexZipCode,
        message: t('Postal code is invalid')
      },
      ...rules
    },
    name: name as Path<FormState>,
    autoComplete: autoComplete
  };
};

export const fieldPropsAddress = <FormState>({
  t,
  rules,
  name = 'address',
  autoComplete = 'street-address',
  required = true
}: FieldPropsArgs<FormState>) => ({
  label: t('Address'),
  rules: {
    required: {
      value: required,
      message: t(`Address can't be blank`)
    },
    ...rules
  },
  name: name as Path<FormState>,
  autoComplete: autoComplete
});

export const fieldPropsFirstName = <FormState>({
  t,
  rules,
  name = 'firstName',
  autoComplete = 'given-name',
  required = true
}: FieldPropsArgs<FormState>) => ({
  label: t('First name'),
  rules: {
    required: {
      value: required,
      message: t(`First name can't be blank`)
    },
    maxLength: {
      value: FIRST_NAME_MAX_LENGTH,
      message: t(
        'First name is too long (maximum is %s characters)',
        FIRST_NAME_MAX_LENGTH
      )
    },
    ...rules
  },
  name: name as Path<FormState>,
  autoComplete: autoComplete
});

export const fieldPropsLastName = <FormState>({
  t,
  rules,
  name = 'lastName',
  autoComplete = 'family-name',
  required = true
}: FieldPropsArgs<FormState>) => ({
  label: t('Last name'),
  rules: {
    required: {
      value: required,
      message: t(`Last name can't be blank`)
    },
    maxLength: {
      value: LAST_NAME_MAX_LENGTH,
      message: t(
        'Last name is too long (maximum is %s characters)',
        LAST_NAME_MAX_LENGTH
      )
    },
    ...rules
  },
  name: name as Path<FormState>,
  autoComplete: autoComplete
});

export const fieldPropsNationalIdentificationNumber = <FormState>({
  t,
  rules,
  name = 'nationalIdentificationNumber',
  autoComplete = 'off',
  required = true
}: FieldPropsArgs<FormState>) => ({
  label: t('National identification number'),
  rules: {
    required: {
      value: required,
      message: t(`National identification number can't be blank`)
    },
    maxLength: {
      value: NATIONAL_IDENTIFICATION_NUMBER_MAX_LENGTH,
      message: t(
        'National identification number is too long (maximum is %s characters)',
        NATIONAL_IDENTIFICATION_NUMBER_MAX_LENGTH
      )
    },
    pattern: fieldRuleNationalIdentificationNumberPattern(t),
    ...rules
  },
  name: name as Path<FormState>,
  autoComplete: autoComplete
});

export const fieldPropsOrganisationName = <FormState>({
  t,
  rules,
  name = 'organisationName',
  autoComplete = 'off',
  required = true
}: FieldPropsArgs<FormState>) => ({
  label: t('Organisation name'),
  rules: {
    required: {
      value: required,
      message: t(`Organisation name can't be blank`)
    },
    maxLength: {
      value: ORGANISATION_NAME_MAX_LENGTH,
      message: t(
        'Organisation name is too long (maximum is %s characters)',
        ORGANISATION_NAME_MAX_LENGTH
      )
    },
    ...rules
  },
  name: name as Path<FormState>,
  autoComplete: autoComplete
});

export const fieldPropsOrganisationNumber = <FormState>({
  t,
  rules,
  name = 'organisationNumber',
  autoComplete = 'off',
  required = true
}: FieldPropsArgs<FormState>) => ({
  label: t('Organisation number'),
  rules: {
    required: {
      value: required,
      message: t(`Organisation number can't be blank`)
    },
    pattern: {
      value: regexOrganisationNumber,
      message: t('Organisation number is invalid')
    },
    ...rules
  },
  name: name as Path<FormState>,
  autoComplete: autoComplete
});
