import { BodyLink, Caption, mq, theme } from '@kvdbil/components';
import React from 'react';
import styled from 'styled-components';
import Link from '~/App/shared/components/Link';
import { BlockButtonRecord } from '~/config/generated/graphql';

const PuffsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;

  ${mq(null, 'tablet')} {
    flex-direction: column;
  }
`;

const Puff = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: box-shadow 0.3s;
  background-color: ${theme.colors.background.light};

  :hover {
    box-shadow: ${theme.elevations.elevation2};
  }

  ${mq(null, 'tablet')} {
    flex-direction: row;
  }
`;

const Image = styled.img<{ backgroundX: number; backgroundY: number }>`
  background-position-x: ${({ backgroundX }) => backgroundX}%;
  background-position-y: ${({ backgroundY }) => backgroundY}%;
  aspect-ratio: 232/137;
  width: 100%;
  object-fit: cover;

  ${mq(null, 'tablet')} {
    flex-direction: row;
    aspect-ratio: 1/1;
    width: 30%;
  }
`;

const TextContainer = styled.div`
  padding: 1rem;
  color: ${theme.colors.text.dark};
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  ${mq(null, 'tablet')} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

type Props = { blockButtons?: BlockButtonRecord[] };

const FeatureBlocksItems = ({ blockButtons }: Props) => {
  return (
    <PuffsContainer>
      {blockButtons?.map((block, index) => {
        if (!block.backgroundImage?.responsiveImage?.src) return null;

        return (
          <Puff key={`${block.id}-${index}`} to={block.linkUrl}>
            <Image
              backgroundX={
                Number(block.backgroundImage?.focalPoint?.x ?? 0.5) * 100
              }
              backgroundY={
                Number(block.backgroundImage?.focalPoint?.y ?? 0.5) * 100
              }
              src={block.backgroundImage?.responsiveImage?.src}
            />
            <TextContainer>
              <BodyLink>{block.title}</BodyLink>
              <Caption>{block.preamble}</Caption>
            </TextContainer>
          </Puff>
        );
      })}
    </PuffsContainer>
  );
};

export default FeatureBlocksItems;
