import React, { useEffect, useMemo, useState } from 'react';
import { searchAuctions } from '~/helpers/orchestration/auctions';
import { extractParamsFromQueryString } from '~/helpers/extractParamsFromQueryString';
import { requestErrorHandler } from '~/helpers/notifyError';
import Auction from '~/App/shared/interfaces/Auction';
import SlideShow from '~/App/shared/components/SlideShow';
import styled from 'styled-components';
import Header from '~/App/shared/components/VehicleCardListBlock/components/Header';
import { CardSkeletonLoader } from './CardSkeletonLoader';
import ProductCard from '~/App/shared/components/ProductCard/ProductCard';
import { ProductCardVariant } from '~/App/shared/components/ProductCard/types';
import useElementWidth from '~/App/shared/hooks/useElementWidth';

const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray.light5};
  margin-bottom: 1rem;
`;

interface Props {
  heading: string;
  linkText: string;
  queryString: string;
  prefetchedObjects?: Auction[];
}

const CarInspirationList = ({
  queryString,
  heading,
  linkText,
  prefetchedObjects
}: Props) => {
  const [isLoading, setIsLoading] = useState(!prefetchedObjects);
  const [featuredCars, setFeaturedCars] = useState<Auction[]>(
    prefetchedObjects ?? []
  );
  const { ref, width } = useElementWidth<HTMLDivElement>();
  const itemsPerSlide = useMemo(() => {
    if (width < 700) {
      return 2;
    } else if (width >= 700 && width < 1023) {
      return 3;
    } else {
      return 4;
    }
  }, [width]);

  useEffect(() => {
    if (!featuredCars?.length) {
      const fetchFeaturedCars = async () => {
        if (!queryString || queryString.split('?').length !== 2) return;
        const params = extractParamsFromQueryString(queryString);
        return await searchAuctions({
          idToken: '',
          cancellable: false,
          offset: 0,
          limit: 20,
          params
        });
      };
      fetchFeaturedCars()
        .then(results => {
          if (results) {
            setFeaturedCars(results.auctions);
          }
        })
        .catch(requestErrorHandler)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [setIsLoading, setFeaturedCars, queryString, featuredCars?.length]);

  if (!isLoading && featuredCars?.length === 0) return null;

  return (
    <>
      <Header
        title={heading}
        color="secondary"
        link={{
          label: linkText,
          url: queryString
        }}
      />

      <Divider />

      <div ref={ref}>
        <SlideShow
          itemsPerSlide={{
            tablet: itemsPerSlide,
            laptop: itemsPerSlide,
            laptopXL: itemsPerSlide
          }}
          alwaysShowPagination
        >
          {isLoading
            ? Array(itemsPerSlide)
                .fill('')
                .map(i => <CardSkeletonLoader key={i} />)
            : featuredCars.map((auction: Auction) => (
                <ProductCard
                  key={auction.id}
                  auction={auction}
                  variant={ProductCardVariant.Regular}
                />
              ))}
        </SlideShow>
      </div>
    </>
  );
};

export default CarInspirationList;
