import { TranslateFunction } from '~/Locale';
import { TextFieldProps } from './types';
import { regexNationalIdentificationNumber } from '~/helpers/regex';

interface FieldRulesArgs<FormState> {
  t: TranslateFunction;
  field?: string;
  message?: string;
  rules?: TextFieldProps<FormState>['rules'];
}

export const fieldRuleIsRequired = <FormState>({
  t,
  field,
  message
}: FieldRulesArgs<FormState>) => ({
  required: {
    value: true,
    message: message ? message : `${field} ${t(`can't be blank`)}`
  }
});

export const fieldRuleNationalIdentificationNumberPattern = (
  t: TranslateFunction
) => ({
  value: regexNationalIdentificationNumber,
  message: t('National identification number is invalid')
});
